import React from 'react';
import Navbar from './component/Navbar/Navbar';
import About from './component/About';
import RBanner from './component/Banner/RippleBanner';
import Portfolio from './component/Portfolio/Portfolio';
import SkillTwo from './component/Skill/SkillTwo';
import Footer from './component/FooterTwo';
import jhonData from './component/jhonData';

export const Homefrelencer = () => (
  <div className="body_wrapper">
    <Navbar mainlogo="logo2.png" stickylogo="logo.png"/>   
    <RBanner jhonData={jhonData} bClass="banner_shap" imag="mel-hero.jpg" textLeft="text-left"/>
    <About aClass="about_area" jhonData={jhonData}/>
    <Portfolio/>
    <SkillTwo/>
    <Footer jhonData={jhonData}/>
  </div>
)
