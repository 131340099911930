import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Fade, Reveal } from 'react-reveal/';
import SectionTitleTwo from '../component/Banner/SectionTitleTwo';

class About extends Component {
    render() {
        var { aClass } = this.props;
        return (
            <section className={`${aClass}`} id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="about_img">
                                <Reveal effect="fadeInLeft" duration={1500}><img src={require('../image/about-white.jpg')} alt=""/></Reveal>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <Fade bottom cascade duration={1000}>
                                <div className="skill_content_two">
                                    <SectionTitleTwo stitle="What I do ?" tCenter="text-right" right btitle="Innovative AI solutions to protect our cyber space." />
                                    <p align="right">I’m Melissa, an "AI Hacker" based in Silicon Valley, using AI for cyber security defense and offense. Linux runtime security w/ eBPF enthusiast and practitioner. Prior to joining Apple, I contributed to US Government research projects and taught at BlackHat USA. Specialized in machine learning, non-linear dynamical systems, virtual reality and biomechanics during my PhD. Contributed to NASA’s space suit engineering program.</p>
                                    
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default About;
