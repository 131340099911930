import React, { Component } from 'react';
import anime from 'animejs/lib/anime.es.js';
import SectionTitleTwo from '../../component/Banner/SectionTitleTwo';
import CounterItem from '../Skill/CounterItem';
import { Fade, Reveal } from 'react-reveal/';

class SkillTwo extends Component {
  componentDidMount() {
    function countup(el, target) {
      let data = { count: 0 };
      anime({
        targets: data,
        count: [0, target],
        duration: 2000,
        round: 1,
        delay: 200,
        easing: 'easeOutCubic',
        update() {
          el.innerText = data.count.toLocaleString();
        }
      });

    }

    function makeCountup(el) {
      const text = el.textContent;
      const target = parseInt(text, 10);

      const io = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            countup(el, target);
            io.unobserve(entry.target);
          }
        });
      });

      io.observe(el);
    }

    const els = document.querySelectorAll('[data-countup]');

    els.forEach(makeCountup);
  }
  render() {
    return (
      <section className="skill_area_two" id="skill">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
            <Fade bottom cascade duration={1000}>
                <div className="skill_content_two">
                
                  <SectionTitleTwo stitle="Skills" right btitle="Some Interesting Facts." />
                  <p>

                    Experienced engineer, speaker and leader. Lived in 5 countries, fluent in 4 languages, 
                    have written computer programs in over 12 languages and hacked my way through many frameworks and 
                    tools cut across machine learning, big data, computer science, statistics and cyber security (offensive security and defense).
                    Former elite gymnast and head coach.

                  </p>

                </div>
                
              </Fade>
              <div className="skill_content_two">
    
                <div className="row">
                  <CounterItem col="col-md-6 s_item" CText='10' pdescription='X Improvements' />
                  <CounterItem col="col-md-6 s_item" CText='580' pdescription='Solutions' />
                  <CounterItem col="col-md-6 s_item" CText='35' pdescription='Innovations' />
                  <CounterItem col="col-md-6 s_item" CText='1000' pdescription='Happy Mistakes' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              {/* <img className="fact_img" src={require('../../image/facts.jpg')} alt="" /> */}
              <Reveal effect="fadeInRight" duration={1500}><img src={require('../../image/about-white2.jpg')} alt=""/></Reveal>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default SkillTwo;