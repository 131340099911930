import React, {Component} from 'react';
import { Fade, Reveal } from 'react-reveal/';
import ItemGrid from './ItemGrid';
import Sectiontitle from '../../component/Banner/Sectiontitle';
import SectionTitleTwo from '../../component/Banner/SectionTitleTwo';

class portfolio extends Component {
    render() {
      var {pClass}= this.props;
      return(
        <section className={`portfolio_area ${pClass}`} id="portfolio">
            <div className="container">
                <SectionTitleTwo tCenter="text-center" stitle="Portfolio" btitle="Some Diverse Perspectives."/>   
                <ItemGrid/>
            </div>
        </section>
      )
    }
  }

  export default portfolio;
