import React, {Component} from 'react';
import Isotope from 'isotope-layout/js/isotope';
import ImagesLoaded from 'imagesloaded/imagesloaded';

class ItemGrid extends Component {

    state = {
        activeItem: '*',
    }

    componentDidMount() {
        var imgLoad = new ImagesLoaded('.grid');
    
        imgLoad.on('progress', function(instance, image) {
            this.iso = new Isotope('.grid', {
                itemSelector: '.grid-item',
                layoutMode: "masonry"
            });
        }); 
        
    }
    onFilterChange = (newFilter) => {
        
        this.setState({activeItem: newFilter});
        if (this.iso === undefined) {
            this.iso = new Isotope('.grid', {
            itemSelector: '.grid-item',
            layoutMode: "masonry"
            });
        }
    
    // this.iso.arrange({ filter: newFilter });
        
      if(newFilter === '*') {
        this.iso.arrange({ filter: `*` });
      } else {
        this.iso.arrange({ filter: `.${newFilter}` });
      }
    }

    onActive = v => v === this.state.activeItem ? 'active' : '';
    render() {
        return(
            <div>
            <ul className="list_style portfolio_menu text-center">
                <li className={`${this.onActive('*')}`} data-wow-delay="0.1s" data-filter="*" onClick={() => {this.onFilterChange("*")}}>ALL</li>
                <li className={`${this.onActive('git')}`} data-wow-delay="0.6s" data-filter="git" onClick={()=> {this.onFilterChange("git")}}>GitHub</li>
                <li className={`${this.onActive('pub')}`} data-wow-delay="0.3s" data-filter="pub" onClick={() => {this.onFilterChange("pub")}}>Publications</li>
              
                <li className={`${this.onActive('tech')}`} data-wow-delay="0.8s" data-filter="tech" onClick={()=> {this.onFilterChange("tech")}}>Tech Outreach</li>
            </ul>	

            <div className="grid row">
                <div className="col-md-3 col-sm-6 col-xs-12 grid-item tech pub">
                    <div className="portfolio hover-style">
                        <img src={require('../../image/portfolio/1.jpg')} alt=""/>
                        <div className="item-img-overlay">
                            <div className="overlay-info text-center">
                                <h6 className="sm-titl">BLACKHAT</h6>
                                <div className="icons">
                                    {/* <a href=".#"><i className="icon_heart_alt"></i></a> */}
                                    <a href="https://www.blackhat.com/us-17/training/applied-data-science-for-security-professionals.html" rel="noopener noreferrer" target="_blank"><i className="icon-magnifying-glass"></i></a>
                                </div>
                            </div>
                        </div>						
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 grid-item pub">
                    <div className="portfolio hover-style">
                    <img src={require('../../image/portfolio/2.jpg')} alt=""/>
                        <div className="item-img-overlay">
                            <div className="overlay-info text-center">
                                <h6 className="sm-titl">PAPERS</h6>
                                <div className="icons">
                                    {/* <a href=".#"><i className="icon_heart_alt"></i></a> */}
                                    <a href="https://scholar.google.com/citations?user=k8zMrEcAAAAJ&hl=en&oi=ao" rel="noopener noreferrer" target="_blank"><i className="icon-magnifying-glass"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>						
                </div>
                
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item tech pub">
                    <div className="portfolio hover-style">
                        <img src={require('../../image/portfolio/10.jpg')} alt=""/>
                        <div className="item-img-overlay">
                            <div className="overlay-info text-center">
                                <h6 className="sm-titl">eBPF SUMMIT</h6>
                                <div className="icons">
                                    {/* <a href=".#"><i className="icon_heart_alt"></i></a> */}
                                    <a href="https://www.youtube.com/watch?v=djv6NNYKNAM" rel="noopener noreferrer" target="_blank"><i className="icon-magnifying-glass"></i></a>
                                </div>
                            </div>
                        </div>	
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item git">
                    <div className="portfolio hover-style">
                    <img src={require('../../image/portfolio/9.jpg')} alt=""/>
                        <div className="item-img-overlay">
                            <div className="overlay-info text-center">
                                <h6 className="sm-titl">AI/ML + SECURITY</h6>
                                <div className="icons">
                                    {/* <a href=".#"><i className="icon_heart_alt"></i></a> */}
                                    <a href="https://www.youtube.com/watch?v=1y1m9Vz93Yo" rel="noopener noreferrer" target="_blank"><i className="icon-magnifying-glass"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 grid-item git">
                    <div className="portfolio hover-style">
                        <img src={require('../../image/portfolio/3.jpg')} alt=""/>
                        <div className="item-img-overlay">
                            <div className="overlay-info text-center">
                                <h6 className="sm-titl">BIG DATA</h6>
                                <div className="icons">
                                    {/* <a href=".#"><i className="icon_heart_alt"></i></a> */}
                                    <a href="https://databricks.com/sparkaisummit/north-america/women-in-ua" rel="noopener noreferrer" target="_blank"><i className="icon-magnifying-glass"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 grid-item tech">
                    <div className="portfolio hover-style">
                        <img src={require('../../image/portfolio/4.png')} alt=""/>
                        <div className="item-img-overlay">
                            <div className="overlay-info text-center">
                                <h6 className="sm-titl">COMMITTEE MEMBER</h6>
                                <div className="icons">
                                    {/* <a href=".#"><i className="icon_heart_alt"></i></a> */}
                                    <a href="https://www.camlis.org/" rel="noopener noreferrer" target="_blank"><i className="icon-magnifying-glass"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
  }

  export default ItemGrid;
